import React, { useEffect, useState } from 'react';
import { fetchStudyCounts, StudyCounts } from '../services/apiService';
import './StudyCountsCard.css'; // Import the CSS file for styling

const StudyCountsCard: React.FC = () => {
    const [counts, setCounts] = useState<StudyCounts | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const getCounts = async () => {
            try {
                const data = await fetchStudyCounts();
                setCounts(data);
            } catch (err) {
                console.error('Error fetching study counts:', err);
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        getCounts();
    }, []);

    if (loading) return <div>Loading study counts...</div>;
    if (error) return <div>Error loading study counts: {error.message}</div>;

    return (
        <div className="study-counts-card-container">
            <div className="study-count-card">
                <div className="count-number">{counts?.totalStudies}</div>
                <div className="count-label">Total Studies</div>
            </div>
            <div className="study-count-card">
                <div className="count-number">{counts?.publishedStudies}</div>
                <div className="count-label"> Studies have been published to Compass AE </div>
            </div>
        </div>
    );
};

export default StudyCountsCard;
