import React from 'react';
import './AnalyticsDashboard.css';
import LoginCountsChart from './LoginCountsChart';
import ApiUsageChart from './ApiUsageChart';
import UsageGraphsChart from './UsageGraphsChart';
import ActionCountsChart from './ActionCountsChart';
import StudyCountsCard from './StudyCountsCard';
import StudiesByCreatorChart from './StudiesByCreatorChart';

const AnalyticsDashboard: React.FC = () => {
    return (
        <div className="container">
            <div className="row dashboard-row">
                <div className="col-md-12">
                    <StudyCountsCard />
                </div>
            </div>
            <div className="row dashboard-row">
                <div className="col-md-6">
                    <div className="dashboard-card">
                        <StudiesByCreatorChart />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="dashboard-card">
                        <LoginCountsChart />
                    </div>
                </div>
            </div>
            <div className="row dashboard-row">
                <div className="col-md-6">
                    <div className="dashboard-card">
                        <UsageGraphsChart />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="dashboard-card">
                        <ActionCountsChart />
                    </div>
                </div>
            </div>
            <div className="row dashboard-row">
                <div className="col-md-12">
                    <div className="dashboard-card">
                        <ApiUsageChart />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsDashboard;
