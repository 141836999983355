
const API_BASE_URL = '/api/dashboard';

export interface ActivityLogDto {
    id: number;
    dateTime: string; 
    productId: number;
    userId: string | null;
    userName: string | null;
    clubId: number;
    sessionId: string | null;
    detail: string | null;
    className: string | null;
    methodName: string | null;
    eventType: string | null;
    description: string | null;
    errorDetails: string | null;
}

export interface LoginCount {
    date: Date; 
    count: number;
}

export const fetchLoginCounts = async (): Promise<LoginCount[]> => {
    const response = await fetch('/api/dashboard/login-counts');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data: LoginCount[] = await response.json();
    return data;
};

export interface ApiUsage {
    apiName: string;
    count: number;
}

export const fetchApiUsage = async (): Promise<ApiUsage[]> => {
    const response = await fetch('/api/dashboard/api-usage');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data: ApiUsage[] = await response.json();
    return data;
};

export const fetchActivityLogs = async (): Promise<ActivityLogDto[]> => {
    const response = await fetch(`${API_BASE_URL}/activity-logs`);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data: ActivityLogDto[] = await response.json();
    return data;
};

export interface ActionCount {
    methodName: string;
    actionCount: number;
}

export const fetchActionCounts = async (): Promise<ActionCount[]> => {
    const response = await fetch(`${API_BASE_URL}/actions`);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data: ActionCount[] = await response.json();
    return data;
};

export interface ErrorLog extends ActivityLogDto {
   
}

export const fetchErrorLogs = async (): Promise<ErrorLog[]> => {
    const response = await fetch('/api/dashboard/errors');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data: ErrorLog[] = await response.json();
    return data;
};

export interface UsageData {
    date: Date;
    usage: number;
}

export const fetchUsageGraphs = async (): Promise<UsageData[]> => {
    const response = await fetch('/api/dashboard/usage-graphs');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data: UsageData[] = await response.json();
    return data;
};


// Function with query parameters
export const fetchAssetActions = async (
    startDate?: Date,
    endDate?: Date
): Promise<ActivityLogDto[]> => {
    const url = new URL(`${API_BASE_URL}/asset-actions`, window.location.origin);
    if (startDate) url.searchParams.append('startDate', startDate.toISOString());
    if (endDate) url.searchParams.append('endDate', endDate.toISOString());

    const response = await fetch(url.toString());
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data: ActivityLogDto[] = await response.json();
    return data;
};

export interface StudyCounts {
    totalStudies: number;
    publishedStudies: number;
}

export interface StudiesByCreator {
    createdBy: string;
    studyCount: number;
}

// Function to fetch study counts
export const fetchStudyCounts = async (): Promise<StudyCounts> => {
    const response = await fetch('/api/dashboard/study-counts');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data: StudyCounts = await response.json();
    return data;
};

// Function to fetch studies by creator
export const fetchStudiesByCreator = async (): Promise<StudiesByCreator[]> => {
    const response = await fetch('/api/dashboard/studies-by-creator');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data: StudiesByCreator[] = await response.json();
    return data;
};
