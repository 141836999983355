import React, { useEffect, useRef, useState } from "react";

import Button from "devextreme-react/button";
import { Template } from "devextreme-react/core/template";
import DataGrid, {
    Column,
    ColumnFixing,
    Button as DGButton,
    Editing,
    FormItem,
    HeaderFilter,
    Lookup,
    Pager,
    Paging,
    RangeRule,
    RequiredRule,
    SearchPanel,
} from "devextreme-react/data-grid";
import LoadPanel from "devextreme-react/load-panel";

import { ScrollView } from "devextreme-react";
import Popup from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import parse from "html-react-parser";
import { BUDGET_CONTENGENCY_SALES_DATA_FIELDS } from "../constants/budgetContingencySalesDataFields";
import { FORMAT } from "../constants/format";
import { STUDY_CAMPUS_DATA_FIELDS } from "../constants/studyCampusDataFields";
import { STUDY_DATA_FIELDS } from "../constants/studyDataFields";
import { newClubListOnlyStore } from "../stores/newClubListOnlyStore";
import { studyStore } from "../stores/studyStore";
import { EditorPreparingEventInternal } from "../types/editorPreparingEventInternal";
import { Study } from "../types/study";
import { ToolbarPreparingEventingInternal } from "../types/toolbarPreparingEventInternal";
import { StudySelect } from "./StudySelect";

const allowedPageSizes = [10, 20, 50, 100];
const defaultPageSize = 10;

const TOOLBAR_TEMPLATE_NAME = "chooseStudy";
interface Row {
    data: Study;
}

const StudySummary = () => {
    const [studyId, setStudyId] = useState<number | null>(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [publishError, setPublishError] = useState(null);
    const dataGridRef = useRef<DataGrid>(null);
    const [loading, setLoading] = useState(false);

    const togglePopupVisible = () => {
        setPopupVisible((isVisible) => !isVisible);
    };

    const onChooseUploadStudy = () => {
        window.location.href = "/study-upload";
    };

    const onStudyChanged = (e: { value?: number }) => {
        let newValue = e.value || null;
        setStudyId(newValue);
    };

    const onPublishButtonClick = async (selectedStudyId: number) => {
        setLoading(true); // progress bar
        try {
            const response = await fetch(
                "/api/publish/" + selectedStudyId,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const result = await response.json();

            if (!response.ok) {
                throw new Error("Network response was not ok");
            } else if(!result.status) {
                    setPublishError(result.message)
            }else{
                notify(
                    {
                        message: "Study published successfully.",
                        position: {
                            my: "center top",
                            at: "center top",
                        },
                    },
                    "success",
                    3000
                );
    
                if (dataGridRef.current) {
                    dataGridRef.current.instance.refresh();
                }
            }
        } catch (error) {
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
            notify(
                {
                    message:
                        "An error occurred while publishing the study. Please try again.",
                    position: {
                        my: "center top",
                        at: "center top",
                    },
                },
                "error",
                3000
            );
        } finally {
            setLoading(false); // Hide the progress bar
        }
    };

    const isPublishButtonVisible = (e: { row: Row }) => {
        return e.row.data.compassPublishDate === null;
    };

    const onSaveNewStudyClick = async () => {
        try {
            var jsonString = JSON.stringify({ studyId: studyId });

            await fetch("/api/studycontext/" + studyId, {
                method: "PUT",
                body: jsonString,
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(handleErrors);

            notify(
                {
                    message: "Study loaded successfully.",
                    position: {
                        my: "center top",
                        at: "center top",
                    },
                },
                "success",
                3000
            );

            if (dataGridRef.current) {
                dataGridRef.current.instance.refresh();
            }

            window.location.href = "/crs-entry";
        } catch {
            notify(
                {
                    message:
                        "An error occured while loading the study. Please try again.",
                    position: {
                        my: "center top",
                        at: "center top",
                    },
                },
                "error",
                3000
            );
        }
    };

    const onToolbarPreparing = (e: ToolbarPreparingEventingInternal) => {
        e.toolbarOptions?.items?.unshift({
            location: "after",
            template: TOOLBAR_TEMPLATE_NAME,
        });
    };

    const hideInfo = () => {
        setStudyId(null);
        togglePopupVisible();
    };

    const hidePublishErrors = () => {
        setPublishError(null);
    };

    const toolbarItemRender = () => {
        return (
            <div>
                <Button
                    width={200}
                    text="Upload Study File"
                    type="normal"
                    stylingMode="contained"
                    useSubmitBehavior={false}
                    onClick={onChooseUploadStudy}
                />
                <Button
                    width={200}
                    text="Choose Study"
                    type="normal"
                    stylingMode="contained"
                    useSubmitBehavior={false}
                    onClick={togglePopupVisible}
                />
            </div>
        );
    };

    const refreshDataGrid = () => {
        dataGridRef.current?.instance.refresh();
    };

    useEffect(() => {
        refreshDataGrid();
    }, [studyId]);

    //Only allow choosing the club when inserting a study.
    const onEditorPreparing = (e: EditorPreparingEventInternal<Study>) => {
        if (
            e.dataField === STUDY_DATA_FIELDS.ENTITY_ID &&
            e.parentType === "dataRow" &&
            e.editorOptions
        ) {
            e.editorOptions.disabled = !e.row?.isNewRow;
        }
    };

    return (
        <div className="p-2">
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{ of: "#gridContainer" }}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
                message="Publishing..."
            />
            <DataGrid
                dataSource={gridDataSource}
                showBorders={true}
                onToolbarPreparing={onToolbarPreparing}
                columnAutoWidth={true}
                onEditorPreparing={onEditorPreparing}
                ref={dataGridRef}
            >
                <Pager
                    visible={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} Studies)"
                />
                <Paging defaultPageSize={defaultPageSize} />
                <ColumnFixing enabled={true} />
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowAdding={true}
                    popup={{ height: "auto" }}
                />
                <HeaderFilter visible={true} allowSearch={true} />
                <SearchPanel visible={true} />
                <Column
                    dataField={STUDY_DATA_FIELDS.ID}
                    caption="Id"
                    visible={false}
                    allowEditing={false}
                />
                <Column
                    dataField={STUDY_DATA_FIELDS.CLUB_NAME}
                    caption="Club Name"
                    allowEditing={false}
                >
                    <FormItem visible={false} />
                </Column>
                <Column
                    dataField={STUDY_DATA_FIELDS.ENTITY_ID}
                    caption="Club Name"
                    visible={false}
                >
                    <RequiredRule />
                    <Lookup
                        dataSource={newClubListOnlyStore}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>
                <Column dataField={STUDY_DATA_FIELDS.YEAR}>
                    <RequiredRule />
                    <RangeRule
                        min={1}
                        message={"Needs to be a non-zero positive value."}
                    />
                </Column>
                <Column dataField={STUDY_DATA_FIELDS.CLIENT_NAME} />
                <Column dataField={STUDY_DATA_FIELDS.CLIENT_SALUTATION} />
                <Column dataField={STUDY_DATA_FIELDS.CLIENT_TITLE} />
                <Column
                    dataField={STUDY_DATA_FIELDS.CB_REPRESENTATIVE}
                    caption="CB Representative"
                />
                <Column
                    dataField={STUDY_DATA_FIELDS.COMPASS_PUBLISH_DATE}
                    dataType="datetime"
                >
                    <FormItem visible={false} />
                </Column>
                <Column dataField={STUDY_DATA_FIELDS.COMPASS_PUBLISHED_BY}>
                    <FormItem visible={false} />
                </Column>
                <Column
                    dataField={STUDY_DATA_FIELDS.FILE_NAME}
                    allowEditing={false}
                >
                    <FormItem visible={false} />
                </Column>
                <Column
                    dataField={`${STUDY_DATA_FIELDS.STUDY_CAMPUS}.${STUDY_CAMPUS_DATA_FIELDS.STREET}`}
                    caption="Campus Street"
                />
                <Column
                    dataField={`${STUDY_DATA_FIELDS.STUDY_CAMPUS}.${STUDY_CAMPUS_DATA_FIELDS.CITY}`}
                    caption="Campus City"
                />
                <Column
                    dataField={`${STUDY_DATA_FIELDS.STUDY_CAMPUS}.${STUDY_CAMPUS_DATA_FIELDS.STATE_PROVINCE}`}
                    caption="Campus State/Province"
                />
                <Column
                    dataField={`${STUDY_DATA_FIELDS.STUDY_CAMPUS}.${STUDY_CAMPUS_DATA_FIELDS.ZIPCODE}`}
                    caption="Campus Zipcode"
                />
                <Column
                    dataField={`${STUDY_DATA_FIELDS.STUDY_CAMPUS}.${STUDY_CAMPUS_DATA_FIELDS.COUNTRY}`}
                    caption="Campus Country"
                />
                <Column
                    dataField={`${STUDY_DATA_FIELDS.BUDGETARY_CONTINGENCY_SALES}.${BUDGET_CONTENGENCY_SALES_DATA_FIELDS.INFLATION_RATE}`}
                    caption="Inflation Rate"
                    format={FORMAT.PERCENT.SINGLE_DECIMAL}
                    editorOptions={{ format: FORMAT.PERCENT.SINGLE_DECIMAL }}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={`${STUDY_DATA_FIELDS.BUDGETARY_CONTINGENCY_SALES}.${BUDGET_CONTENGENCY_SALES_DATA_FIELDS.SALES_TAX_RATE}`}
                    caption="Sales Tax Rate"
                    format={FORMAT.PERCENT.TWO_DECIMALS}
                    editorOptions={{ format: FORMAT.PERCENT.TWO_DECIMALS }}
                />
                <Column
                    dataField={`${STUDY_DATA_FIELDS.BUDGETARY_CONTINGENCY_SALES}.${BUDGET_CONTENGENCY_SALES_DATA_FIELDS.BUDGETARY_CONTINGENCY_SALES_PERCENTAGE}`}
                    caption="Contingency Rate"
                    format={FORMAT.PERCENT.SINGLE_DECIMAL}
                    editorOptions={{ format: FORMAT.PERCENT.SINGLE_DECIMAL }}
                />
                <Column
                    dataField={STUDY_DATA_FIELDS.DATE_CREATED}
                    visible={false}
                    allowEditing={false}
                >
                    <FormItem visible={false} />
                </Column>
                <Column
                    dataField={STUDY_DATA_FIELDS.CREATED_BY}
                    allowEditing={false}
                >
                    <FormItem visible={false} />
                </Column>
                <Column
                    dataField={STUDY_DATA_FIELDS.DATE_MODIFIED}
                    visible={false}
                    allowEditing={false}
                >
                    <FormItem visible={false} />
                </Column>
                <Column
                    dataField={STUDY_DATA_FIELDS.MODIFIED_BY}
                    allowEditing={false}
                >
                    <FormItem visible={false} />
                </Column>
                <Column type="buttons" width={130}>
                    <DGButton name="edit" />
                    <DGButton name="delete" />
                    <DGButton
                        text="Publish"
                        visible={isPublishButtonVisible}
                        onClick={(e: { row: Row }) =>
                            onPublishButtonClick(e.row.data.id)
                        }
                    />
                </Column>

                <Template
                    name={TOOLBAR_TEMPLATE_NAME}
                    render={toolbarItemRender}
                />
            </DataGrid>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={true}
                closeOnOutsideClick={true}
                showTitle={true}
                title="Choose Study"
                width={500}
                height={350}
            >
                <div className="dx-form">
                    <div className="dx-fieldset">
                        <div className="dx-field">
                            <StudySelect
                                className="dx-field-value"
                                width={"100%"}
                                onValueChanged={onStudyChanged}
                            />
                        </div>
                        <Button
                            width="200"
                            text="Load Study"
                            type="normal"
                            stylingMode="contained"
                            useSubmitBehavior={false}
                            disabled={!studyId}
                            onClick={onSaveNewStudyClick}
                        />
                    </div>
                </div>
            </Popup>
            {publishError && (
                <Popup
                    visible={publishError}
                    onHiding={hidePublishErrors}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Publish Errors"
                    fullScreen={true}
                >
                    <ScrollView height={'100%'} width={'100%'}>{parse(publishError)}</ScrollView>
                </Popup>
            )}
        </div>
    );
};

function handleErrors(response: Response) {
    if (!response.ok) throw Error(response.statusText);
    return response;
}

const gridDataSource = {
    store: studyStore,
};

export default StudySummary;
