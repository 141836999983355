import React, { useEffect, useState } from 'react';
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    Label,
    Tooltip,
    ZoomAndPan
} from 'devextreme-react/chart';
import { fetchLoginCounts, LoginCount } from '../services/apiService';

const LoginCountsChart: React.FC = () => {
    const [data, setData] = useState<LoginCount[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const loginCounts = await fetchLoginCounts();
                // Parse date strings into Date objects
                const parsedData = loginCounts.map((item) => ({
                    ...item,
                    date: new Date(item.date),
                }));
                setData(parsedData);
            } catch (err) {
                console.error('Error fetching login counts:', err);
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    if (loading) return <div>Loading login counts...</div>;
    if (error) return <div>Error loading login counts: {error.message}</div>;

    return (
        <div>
            <h3 className="dashboard-heading">User Login Counts Over Time</h3>
            <Chart dataSource={data} size={{ height: 400 }} >
                <ArgumentAxis argumentType="datetime">
                    <Label
                        format={{
                            formatter: (value: Date) =>
                                value.toLocaleDateString(undefined, {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                }),
                        }}
                        rotationAngle={45}
                    />
                </ArgumentAxis>
                <ValueAxis />
                <Series type="line" argumentField="date" valueField="count" />
                <Tooltip enabled={true} />
                <ZoomAndPan
                    argumentAxis="both"
                    valueAxis="both"
                    dragToZoom={true}
                    panKey="shift"
                />
            </Chart>
        </div>
    );
};

export default LoginCountsChart;
