import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledButtonDropdown } from 'reactstrap';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';
//import { Offline, Online } from 'react-detect-offline';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';


export class LoginMenu extends Component {

    

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: null,
            menuClassName: 'dx-menu-item dx-menu-item-text'
        };
        console.log("this.state: ", this.state);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }

    }

    
    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to="/study-setup-summary">Study Setup</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to="/crs-entry">Asset Entry</NavLink>
            </NavItem>
            <UncontrolledButtonDropdown>
                <DropdownToggle caret className="dx-menu-item dx-menu-item-text">
                    Structures & Rooms
                </DropdownToggle>
                <DropdownMenu className="text-dark dx-menu-item-text">
                    <DropdownItem tag={Link} to="/study-structure" className="text-dark dx-menu-item-text">Study Structures</DropdownItem>
                    <DropdownItem tag={Link} to="/study-room" className="text-dark dx-menu-item">Study Rooms</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <UncontrolledButtonDropdown>
                <DropdownToggle caret className="nav-link">
                    Asset Summaries
                </DropdownToggle>
                <DropdownMenu className="text-dark dx-menu-item-text">
                    <DropdownItem tag={Link} to="/asset-summary" className="text-dark">Asset Summary For Export</DropdownItem>
                    <DropdownItem tag={Link} to="/asset-summary-drill-down" className="text-dark">Asset Summary Drill Down</DropdownItem>
                    <DropdownItem tag={Link} to="/all-assets" className="text-dark">All Assets</DropdownItem>
                    <DropdownItem tag={Link} to="/asset-bulk-edit" className="text-dark">Bulk Assets Editor</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <UncontrolledButtonDropdown>
                <DropdownToggle caret className={this.state.menuClassName}>
                    Lookups
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem tag={Link} to="/asset-coding-class" className="text-dark">Asset Coding Class</DropdownItem>
                    <DropdownItem tag={Link} to="/asset-group" className="text-dark">Asset Group</DropdownItem>
                    <DropdownItem tag={Link} to="/asset-service-life" className="text-dark">Asset Service Life</DropdownItem>
                    <DropdownItem tag={Link} to="/asset-type" className="text-dark">Asset Type</DropdownItem>
                    <DropdownItem tag={Link} to="/room-type" className="text-dark">Room Type</DropdownItem>
                    <DropdownItem tag={Link} to="/structure-location-type" className="text-dark">Structure/Location Type</DropdownItem>
                    <DropdownItem tag={Link} to="/unit-of-measure" className="text-dark">Unit Of Measure</DropdownItem>
                    <DropdownItem tag={Link} to="/inflation" className="text-dark">Inflation</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <div className="btn">
                <div className="offline"><FontAwesomeIcon icon="cloud-showers-heavy" /></div>
                <div className="online"> <FontAwesomeIcon icon="signal" color="green" /></div>
            </div>
            <UncontrolledButtonDropdown>
                <DropdownToggle caret className="nav-link">
                    Analytics
                </DropdownToggle>
                <DropdownMenu className="text-dark dx-menu-item-text">
                    <DropdownItem tag={Link} to="/dashboard" className="text-dark">Analytics Dashboard</DropdownItem>
                    <DropdownItem tag={Link} to="/activity-logs" className="text-dark">Activity Log</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>Profile</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
            </NavItem>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
