import React, { useState } from "react";

import Box, { Item } from "devextreme-react/box";
import FileUploader from "devextreme-react/file-uploader";
import SelectBox from "devextreme-react/select-box";
import DataSource from "devextreme/data/data_source";
import parse from "html-react-parser";
import { Container } from "reactstrap";
import { newClubListOnlyStore } from "../stores/newClubListOnlyStore";
import { StudySelect } from "./StudySelect";

const StudyUpload = () => {
    const [selectedFiles, setSelectedFiles] = useState<File[] | undefined>();
    const [uploadResponse, setUploadResponse] = useState("");
    const [uploadNewStudyUrl, setUploadNewStudyUrl] = useState(
        "/api/upload"
    );
    const [replaceStudyUrl, setReplaceStudyUrl] = useState(
        "/api/replace"
    );

    const onNewStudyClubIdChanged = (e: { value?: number }) => {
        const selectedClubId = e.value;
        if (selectedClubId) {
            setUploadNewStudyUrl((currentUrl) =>
                updateQueryStringParameter(
                    currentUrl,
                    "id",
                    selectedClubId
                )
            );
        }
    };

    const onReplaceStudyIdChanged = (e: { value?: number }) => {
        const selectedStudyId = e.value;
        if (selectedStudyId) {
            setReplaceStudyUrl((currentUrl) =>
                updateQueryStringParameter(
                    currentUrl,
                    "id",
                    selectedStudyId
                )
            );
        }
    };

    const onSelectedFilesChanged = (e: { value?: File[] }) => {
        setUploadResponse("")
        setSelectedFiles(e.value);
    };

    const onUploaded = (e: { request?: XMLHttpRequest }) => {
        const request = e.request;
        if (request) {
            var resp = JSON.parse(request.response);
            setUploadResponse(resp.message);
        }
    };

    const updateQueryStringParameter = (
        uploadUrl: string,
        key: string,
        value: number
    ) => {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uploadUrl.indexOf("?") !== -1 ? "&" : "?";
        if (uploadUrl.match(re)) {
            return uploadUrl.replace(re, "$1" + key + "=" + value + "$2");
        } else {
            return uploadUrl + separator + key + "=" + value;
        }
    };

    const clubListDataSource = new DataSource({
        store: newClubListOnlyStore,
    });

    return (
        <Container>
            <Box direction="row" width="100%">
                <Item ratio={1}>
                    <h3>Upload New Study</h3>
                    <p style={{ color: "black", width: "80%" }}>
                        Note: Only new clubs that have no uploaded studies 
                        can be selected. Use <b>Replace Existing Study </b>to replace an existing study.
                    </p>
                    <p>Choose a Club for this Study:</p>
                    <SelectBox
                        dataSource={clubListDataSource}
                        valueExpr="id"
                        displayExpr="name"
                        visible={true}
                        onValueChanged={onNewStudyClubIdChanged}
                        searchEnabled={true}
                        width="75%"
                    />
                    <FileUploader
                        multiple={false}
                        uploadMode="useButtons"
                        uploadUrl={uploadNewStudyUrl}
                        onUploaded={onUploaded}
                        onValueChanged={onSelectedFilesChanged}
                    />
                </Item>
                <Item ratio={1}>
                    <h3>Replace Existing Study</h3>
                    <p style={{ color: "red", width: "80%" }}>
                        Warning: This will completely overwrite the chosen study
                        in the database. Make sure you are choosing the correct
                        study to replace.
                    </p>
                    <p>Choose an Existing Study to Completely Replace:</p>
                    <StudySelect
                        onValueChanged={onReplaceStudyIdChanged}
                        width={"75%"}
                    />
                    <FileUploader
                        multiple={false}
                        uploadMode="useButtons"
                        uploadUrl={replaceStudyUrl}
                        onUploaded={onUploaded}
                        onValueChanged={onSelectedFilesChanged}
                    />
                </Item>
            </Box>

            <hr />
            {selectedFiles && (
                <div
                    className="content"
                    style={{
                        display: selectedFiles.length > 0 ? "block" : "none",
                    }}
                >
                    <div>
                        <h4>File Details</h4>
                        {selectedFiles.map((file, i) => {
                            const lastModifiedDate = new Date(
                                file.lastModified
                            );
                            return (
                                <div className="selected-item" key={i}>
                                    <span>
                                        {`Name: ${file.name}`}
                                        <br />
                                    </span>
                                    <span>
                                        {`Size ${file.size}`}
                                        <br />
                                    </span>
                                    <span>
                                        {`Type ${file.size}`}
                                        <br />
                                    </span>
                                    <span>{`Last Modified Date: ${lastModifiedDate}`}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <div
                className="uploadResults"
                style={{
                    display:
                        selectedFiles && selectedFiles.length > 0
                            ? "block"
                            : "none",
                }}
            >
                <div>
                    <h3>Upload Results</h3>
                    <div>{parse(uploadResponse)}</div>
                </div>
            </div>
        </Container>
    );
};

export default StudyUpload;
