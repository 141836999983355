import React, { useEffect, useState } from 'react';
import {
    Chart,
    Series,
    ArgumentAxis,
    Label,
    Tooltip,
    ZoomAndPan,
    ScrollBar,
} from 'devextreme-react/chart';
import { fetchActionCounts, ActionCount } from '../services/apiService';

const ActionCountsChart: React.FC = () => {
    const [data, setData] = useState<ActionCount[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const actionCounts = await fetchActionCounts();
                setData(actionCounts);
            } catch (err) {
                console.error('Error fetching action counts:', err);
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    if (loading) return <div>Loading action counts...</div>;
    if (error) return <div>Error loading action counts: {error.message}</div>;

    return (
        <div>
            <h3 className="dashboard-heading">Action Counts by API</h3>
            <Chart dataSource={data} size={{ height: 400 }} rotated={true}>
                <ArgumentAxis>
                    <Label
                        overlappingBehavior="wrap" // Wraps long labels
                    />
                </ArgumentAxis>
                <Series type="bar" argumentField="methodName" valueField="actionCount" />
                <Tooltip enabled={true} />
                <ZoomAndPan
                    argumentAxis="both"
                    valueAxis="both"
                    dragToZoom={true}
                    panKey="shift"
                />
                <ScrollBar visible={false} />
            </Chart>
        </div>
    );
};

export default ActionCountsChart;
