import React, { useEffect, useState } from 'react';
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    Legend,
    Tooltip,
    Label,
    ZoomAndPan,
    ScrollBar,
} from 'devextreme-react/chart';
import { fetchUsageGraphs, UsageData } from '../services/apiService';

const UsageGraphsChart: React.FC = () => {
    const [data, setData] = useState<UsageData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const usageData = await fetchUsageGraphs();
                // Parse date strings into Date objects
                const parsedData = usageData.map((item) => ({
                    ...item,
                    date: new Date(item.date),
                }));
                setData(parsedData);
            } catch (err) {
                console.error('Error fetching usage data:', err);
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    if (loading) return <div>Loading usage data...</div>;
    if (error) return <div>Error loading usage data: {error.message}</div>;

    return (
        <div>
            <h3 className="dashboard-heading">Usage Over Time</h3>
            <Chart dataSource={data} size={{ height: 400 }}>
                <ArgumentAxis argumentType="datetime">
                    <Label
                        format={{
                            formatter: (value: Date) =>
                                value.toLocaleDateString(undefined, {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                }),
                        }}
                        rotationAngle={45}
                    />
                </ArgumentAxis>
                <ValueAxis />
                <Series type="area" argumentField="date" valueField="usage" />
                <Legend visible={true} />
                <Tooltip enabled={true} />
                <ZoomAndPan
                    argumentAxis="both"
                    valueAxis="both"
                    dragToZoom={true}
                    panKey="shift"
                />
                <ScrollBar visible={false} />
            </Chart>
        </div>
    );
};

export default UsageGraphsChart;
