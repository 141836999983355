import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    Column,
    Pager,
    Paging,
    FilterRow,
    FilterPanel,
    SearchPanel,
    HeaderFilter
} from 'devextreme-react/data-grid';
import { fetchActivityLogs, ActivityLogDto } from '../services/apiService';

const ActivityLogsGrid: React.FC = () => {
    const [data, setData] = useState<ActivityLogDto[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const logs = await fetchActivityLogs();
                setData(logs);
            } catch (err) {
                console.error('Error fetching activity logs:', err);
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    if (loading) return <div>Loading activity logs...</div>;
    if (error) return <div>Error loading activity logs: {error.message}</div>;

    return (
        <div>
            <div style={{ width: '80%', margin: '0 auto' }}>
                <h3 className="dashboard-heading">Activity Logs</h3>
                <DataGrid
                    dataSource={data}
                    showBorders={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    keyExpr="id" // Important for performance
                >
                    <FilterPanel visible={true} />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <SearchPanel visible={true} />
                    <Paging defaultPageSize={20} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} />
                    <Column dataField="dateTime" dataType="datetime" format="MMMM dd, yyyy hh:mm a" sortOrder="desc" caption="Date/Time" />
                    <Column dataField="userName" caption="User" />
                    <Column dataField="methodName" caption="Action" />
                    <Column dataField="description" />
                    <Column dataField="eventType" />
                    <Column dataField="className" />
                    </DataGrid>
            </div>
        </div>
    );
};

export default ActivityLogsGrid;
