import React, { useEffect, useState } from 'react';
import { Chart, Series, ArgumentAxis, Label, Tooltip, ZoomAndPan } from 'devextreme-react/chart';
import { fetchStudiesByCreator, StudiesByCreator } from '../services/apiService';

const StudiesByCreatorChart: React.FC = () => {
    const [data, setData] = useState<StudiesByCreator[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const studies = await fetchStudiesByCreator();
                setData(studies);
            } catch (err) {
                console.error('Error fetching studies by creator:', err);
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    if (loading) return <div>Loading studies by creator...</div>;
    if (error) return <div>Error loading studies by creator: {error.message}</div>;

    return (
        <div>
            <h3 className="dashboard-heading">CB Representative Study Count</h3>
            <Chart dataSource={data} size={{ height: 400 }} >
                <ArgumentAxis>
                    <Label rotationAngle={45} overlappingBehavior="rotate" />
                </ArgumentAxis>
                <Series type="bar" argumentField="createdBy" valueField="studyCount" />
                <Tooltip enabled={true} />
                <ZoomAndPan
                    argumentAxis="both"
                    valueAxis="both"
                    dragToZoom={true}
                    panKey="shift"
                />
            </Chart>
        </div>
    );
};

export default StudiesByCreatorChart;
