import React, { useEffect, useState } from 'react';
import { PieChart, Series, Label, Connector, Tooltip } from 'devextreme-react/pie-chart';
import { fetchApiUsage, ApiUsage } from '../services/apiService';

const ApiUsageChart: React.FC = () => {
    const [data, setData] = useState<ApiUsage[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const getData = async () => {
            try {
                const apiUsageData = await fetchApiUsage();
                setData(apiUsageData);
            } catch (err) {
                console.error('Error fetching API usage data:', err);
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    if (loading) return <div>Loading API usage data...</div>;
    if (error) return <div>Error loading API usage data: {error.message}</div>;

    return (
        <div>
            <h3 className="dashboard-heading">Frequently Used APIs</h3>
            <PieChart dataSource={data} palette="Bright" >
                <Series argumentField="apiName" valueField="count">
                    <Label visible={true}>
                        <Connector visible={true} />
                    </Label>
                </Series>
                <Tooltip enabled={true} />
            </PieChart>
        </div>
    );
};

export default ApiUsageChart;
